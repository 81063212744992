var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-title',{staticClass:"h1 justify-center"},[_vm._v("Editar Serviços")]),_c('v-card-subtitle',{staticClass:"h2 text-center"},[_vm._v("Transportadora "+_vm._s(_vm.convenioASerEditado.transportadora.nome))]),_c('v-divider',{staticClass:"mb-4"}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"servicos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.servicos,"error-messages":errors,"loading":_vm.loadingServicos,"menu-props":{ offsetY: true },"item-text":"descricao","item-value":"id","label":"Serviços","prepend-icon":"mdi-room-service","hint":"Selecione os serviços do convênio","persistent-hint":"","chips":"","outlined":"","multiple":"","required":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.quantidadeServicosSelecionados > 0
                          ? 'indigo darken-4'
                          : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" Todos os serviços ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                          var item = ref.item;
                          var index = ref.index;
return [(index < _vm.limiteServicosExibido)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.descricao))])]):_vm._e(),(index == _vm.limiteServicosExibido)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.convenio.servicos.length - _vm.limiteServicosExibido)+" outros) ")]):_vm._e()]}}],null,true),model:{value:(_vm.convenio.servicos),callback:function ($$v) {_vm.$set(_vm.convenio, "servicos", $$v)},expression:"convenio.servicos"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{attrs:{"disabled":invalid,"value":"Atualizar"},on:{"close":_vm.close,"save":_vm.save}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }