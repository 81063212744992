var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',{attrs:{"href":"#ativos"},on:{"change":_vm.getAtivos}},[_vm._v("Ativos")]),_c('v-tab',{attrs:{"href":"#naoativos"},on:{"change":_vm.getNaoAtivos}},[_vm._v("Não ativos")]),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',{attrs:{"value":"ativos"}},[_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.convenios,"sort-by":"transportadora"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Convênios ativos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":_vm.getAtivos}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer'),(_vm.canCreate && _vm.empresaAtual.gate)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$emit('cadastrar', 'cadastrar')}}},[_vm._v("Novo Convênio")]):_vm._e()],1)]},proxy:true},{key:"item.transportadora.contatos",fn:function(ref){
var item = ref.item;
return _vm._l((item.transportadora.contatos),function(contato,index){return _c('div',{key:index},[_c('v-icon',{attrs:{"title":"Contato","small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(contato.nome)+" - "+_vm._s(contato.telefone)+" ")],1)})}},{key:"item.servicos",fn:function(ref){
var item = ref.item;
return _vm._l((item.servicos),function(servico,index){return _c('div',{key:index},[_c('v-icon',{attrs:{"title":"Contato","small":""}},[_vm._v("mdi-room-service")]),_vm._v(" "+_vm._s(servico.descricao)+" ")],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Desativar Convênio","small":"","color":"red"},on:{"click":function($event){return _vm.handleAction(item, 'desativar')}}},[_vm._v("mdi-cancel")]):_vm._e(),(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Editar Convênio","small":"","color":"black"},on:{"click":function($event){return _vm.$emit('editar', item)}}},[_vm._v("mdi-square-edit-outline")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"title":"Remover Convênio","small":"","color":"black"},on:{"click":function($event){return _vm.handleAction(item, 'remover')}}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)]),_c('v-tab-item',{attrs:{"value":"naoativos"}},[_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.conveniosNaoAtivos,"sort-by":"transportadora"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Convênios não ativos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.canList)?_c('v-icon',{attrs:{"title":"Atualizar","medium":""},on:{"click":_vm.getNaoAtivos}},[_vm._v("mdi-refresh")]):_vm._e(),_c('v-spacer')],1)]},proxy:true},{key:"item.transportadora.contatos",fn:function(ref){
var item = ref.item;
return _vm._l((item.transportadora.contatos),function(contato,index){return _c('div',{key:index},[_c('v-icon',{attrs:{"title":"Contato","small":""}},[_vm._v("mdi-phone")]),_vm._v(" "+_vm._s(contato.nome)+" - "+_vm._s(contato.telefone)+" ")],1)})}},{key:"item.servicos",fn:function(ref){
var item = ref.item;
return _vm._l((item.servicos),function(servico,index){return _c('div',{key:index},[_c('v-icon',{attrs:{"title":"Contato","small":""}},[_vm._v("mdi-room-service")]),_vm._v(" "+_vm._s(servico.descricao)+" ")],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdate)?_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Ativar Convênio","small":"","color":"green"},on:{"click":function($event){return _vm.handleAction(item, 'ativar')}}},[_vm._v("mdi-undo-variant")]):_vm._e(),(_vm.canDelete)?_c('v-icon',{attrs:{"title":"Remover Convênio","small":"","color":"black"},on:{"click":function($event){return _vm.handleAction(item, 'remover')}}},[_vm._v("mdi-delete")]):_vm._e()]}},(!_vm.canList)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"red--text"},[_vm._v("Você não tem permissão para visualizar as informações desta tela")])]},proxy:true}:null],null,true)})],1)])],1),_c('confirm-dialog',{on:{"confirm":_vm.confirmDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }