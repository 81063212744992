<template>
  <v-container>
    <v-card-title class="h1 justify-center">Editar Serviços</v-card-title>
    <v-card-subtitle class="h2 text-center"
      >Transportadora
      {{ convenioASerEditado.transportadora.nome }}</v-card-subtitle
    >
    <v-divider class="mb-4" />
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form>
        <v-row justify="center">
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="servicos"
              rules="required"
            >
              <v-select
                v-model="convenio.servicos"
                :items="servicos"
                :error-messages="errors"
                :loading="loadingServicos"
                :menu-props="{ offsetY: true }"
                item-text="descricao"
                item-value="id"
                label="Serviços"
                prepend-icon="mdi-room-service"
                hint="Selecione os serviços do convênio"
                persistent-hint
                chips
                outlined
                multiple
                required
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          quantidadeServicosSelecionados > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold">
                        Todos os serviços
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>

                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < limiteServicosExibido">
                    <span>{{ item.descricao }}</span>
                  </v-chip>
                  <span
                    v-if="index == limiteServicosExibido"
                    class="grey--text text-caption"
                  >
                    (+{{ convenio.servicos.length - limiteServicosExibido }}
                    outros)
                  </span>
                </template>
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="6">
            <v-card-actions>
              <v-spacer />
              <buttons-cadastro
                @close="close"
                @save="save"
                :disabled="invalid"
                value="Atualizar"
              />
            </v-card-actions>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    convenioASerEditado: {
      type: Object,
      required: true,
    },
  },

  name: 'EditarConvenio',

  components: { ButtonsCadastro },

  data: function () {
    return {
      limiteServicosExibido: 2,
      loadingServicos: true,
      convenio: {
        public_id: this.convenioASerEditado.public_id,
        servicos: this.extraiIdsDosServicos(this.convenioASerEditado.servicos),
      },
    }
  },

  created: async function () {
    this.negarAcessoANaoGates()

    await this.getServicos({ proprietario: this.empresaAtual.public_id })
    this.loadingServicos = false
  },

  watch: {
    convenio: {
      deep: true,
      handler: function () {
        this.negarAcessoANaoGates()
      },
    },
  },

  computed: {
    ...mapState('patio', ['servicos']),
    ...mapState('auth', ['empresaAtual']),

    quantidadeServicosSelecionados: function () {
      return this.convenio.servicos ? this.convenio.servicos.length : 0
    },
    selecionouTodosOsServicos: function () {
      return this.quantidadeServicosSelecionados === this.servicos.length
    },
    selecionouAlgunsServicos: function () {
      return (
        this.quantidadeServicosSelecionados > 0 &&
        !this.selecionouTodosOsServicos
      )
    },
    icon: function () {
      if (this.selecionouTodosOsServicos) return 'mdi-close-box'
      else if (this.selecionouAlgunsServicos) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    ...mapActions('patio', ['getServicos', 'editConvenio']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    close: function () {
      this.$emit('close', 'listar')
    },
    save: async function () {
      try {
        await this.editConvenio(this.convenio)
        this.successMessage('Convênio editado com sucesso!')
        this.close()
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    toggle: function () {
      this.$nextTick(() => {
        if (this.selecionouTodosOsServicos) {
          this.convenio.servicos = []
        } else {
          this.convenio.servicos = this.extraiIdsDosServicos(this.servicos)
        }
      })
    },
    negarAcessoANaoGates: function () {
      if (!this.empresaAtual.gate) {
        this.errorMessage('Somente gates podem editar um convênio')
        this.close()
      }
    },
    extraiIdsDosServicos: function (servicos) {
      return servicos.map((servico) => servico.id)
    },
  },
}
</script>
