<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Convênios">
          <cadastrar-convenio
            v-if="acao === 'cadastrar'"
            @close="alterarAcao"
          />
          <editar-convenio
            v-else-if="acao === 'editar'"
            @close="alterarAcao"
            :convenioASerEditado="convenioASerEditado"
          />
          <listar-convenio
            v-else-if="acao === 'listar'"
            @cadastrar="alterarAcao"
            @editar="editarConvenio"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CadastrarConvenio from '@/components/patio/Convenio/CadastrarConvenio'
import ListarConvenio from '@/components/patio/Convenio/ListarConvenio'
import EditarConvenio from '@/components/patio/Convenio/EditarConvenio'

export default {
  name: 'Convenio',

  components: {
    CadastrarConvenio,
    ListarConvenio,
    EditarConvenio,
  },

  data: function () {
    return {
      page: {
        title: 'Gerenciamento de Convênios',
      },
      breadcrumbs: [
        {
          text: 'Financeiro',
          disabled: true,
        },
        {
          text: 'Convênios',
          disabled: false,
        },
      ],
      // cadastrar, listar, editar
      acao: 'listar',
      convenioASerEditado: {},
    }
  },
  methods: {
    alterarAcao: function (acao) {
      this.acao = acao
    },
    editarConvenio: function (convenio) {
      this.convenioASerEditado = convenio
      this.acao = 'editar'
    },
  },
}
</script>
