<template>
  <v-row justify="center">
    <v-dialog v-model="dialog.show" persistent :retain-focus="false" max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ dialog.title }}
        </v-card-title>
        <v-card-text>{{ dialog.message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reset"> Não </v-btn>
          <v-btn color="red darken-1" text @click="confirm"> Sim </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('template', ['dialog']),
  },
  methods: {
    ...mapActions('template', ['closeDialog']),
    reset() {
      this.closeDialog()
    },
    confirm() {
      this.closeDialog()
      this.$emit('confirm')
    },
  },
}
</script>
