<template>
  <div>
    <v-tabs v-model="tabs">
      <v-tab href="#ativos" @change="getAtivos">Ativos</v-tab>
      <v-tab href="#naoativos" @change="getNaoAtivos">Não ativos</v-tab>
      <v-tabs-items v-model="tabs">
        <v-tab-item value="ativos">
          <div class="mt-4">
            <v-data-table
              :headers="headers"
              :items="convenios"
              sort-by="transportadora"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Convênios ativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon
                    v-if="canList"
                    title="Atualizar"
                    medium
                    @click="getAtivos"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer />
                  <v-btn
                    v-if="canCreate && empresaAtual.gate"
                    color="primary"
                    dark
                    class="mb-2"
                    @click="$emit('cadastrar', 'cadastrar')"
                    >Novo Convênio</v-btn
                  >
                </v-toolbar>
              </template>
              <template v-slot:[`item.transportadora.contatos`]="{ item }">
                <div
                  v-for="(contato, index) in item.transportadora.contatos"
                  :key="index"
                >
                  <v-icon title="Contato" small>mdi-phone</v-icon>
                  {{ contato.nome }} - {{ contato.telefone }}
                </div>
              </template>
              <template v-slot:[`item.servicos`]="{ item }">
                <div v-for="(servico, index) in item.servicos" :key="index">
                  <v-icon title="Contato" small>mdi-room-service</v-icon>
                  {{ servico.descricao }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="canUpdate"
                  title="Desativar Convênio"
                  small
                  class="mr-2"
                  color="red"
                  @click="handleAction(item, 'desativar')"
                  >mdi-cancel</v-icon
                >
                <v-icon
                  v-if="canUpdate"
                  title="Editar Convênio"
                  small
                  class="mr-2"
                  color="black"
                  @click="$emit('editar', item)"
                  >mdi-square-edit-outline</v-icon
                >
                <v-icon
                  v-if="canDelete"
                  title="Remover Convênio"
                  small
                  color="black"
                  @click="handleAction(item, 'remover')"
                  >mdi-delete</v-icon
                >
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <v-tab-item value="naoativos">
          <div class="mt-4">
            <v-data-table
              :headers="headers"
              :items="conveniosNaoAtivos"
              sort-by="transportadora"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Convênios não ativos</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-icon v-if="canList" title="Atualizar" medium @click="getNaoAtivos"
                    >mdi-refresh</v-icon
                  >
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
              <template v-slot:[`item.transportadora.contatos`]="{ item }">
                <div
                  v-for="(contato, index) in item.transportadora.contatos"
                  :key="index"
                >
                  <v-icon title="Contato" small>mdi-phone</v-icon>
                  {{ contato.nome }} - {{ contato.telefone }}
                </div>
              </template>
              <template v-slot:[`item.servicos`]="{ item }">
                <div v-for="(servico, index) in item.servicos" :key="index">
                  <v-icon title="Contato" small>mdi-room-service</v-icon>
                  {{ servico.descricao }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  v-if="canUpdate"
                  title="Ativar Convênio"
                  small
                  class="mr-2"
                  color="green"
                  @click="handleAction(item, 'ativar')"
                  >mdi-undo-variant</v-icon
                >
                <v-icon
                  v-if="canDelete"
                  title="Remover Convênio"
                  small
                  color="black"
                  @click="handleAction(item, 'remover')"
                  >mdi-delete</v-icon
                >
              </template>
              <template #no-data v-if="!canList">
                <span class="red--text"
                  >Você não tem permissão para visualizar as informações desta
                  tela</span
                >
              </template>
            </v-data-table>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <confirm-dialog @confirm="confirmDialog" />
    </v-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ConfirmDialog from '@/components/template/ConfirmDialog'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'ListarConvenio',

  components: { ConfirmDialog },

  data: function () {
    return {
      tabs: null,
      dialog: false,
      confirmDialogItem: null,
      confirmDialogAction: '',

      headers: [
        { text: 'Transportadora', value: 'transportadora.nome' },
        { text: 'Cnpj', value: 'transportadora.cnpj' },
        { text: 'Cidade', value: 'transportadora.cidade' },
        { text: 'Estado', value: 'transportadora.estado' },
        { text: 'Contatos', value: 'transportadora.contatos' },
        { text: 'Serviços', value: 'servicos' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState('patio', ['convenios', 'conveniosNaoAtivos']),
    ...mapState('auth', ['empresaAtual']),

    permission() {
      return Sequences.Convenios.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  created: async function () {
    if (this.canList) {
      await this.getAtivos()
    }
  },
  methods: {
    ...mapActions('patio', [
      'getConvenios',
      'getConveniosByParams',
      'desativarConvenio',
      'ativarConvenio',
      'removerConvenio',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    getAtivos: async function () {
      if (this.canList) {
        await this.getConvenios()
      }
    },
    getNaoAtivos: async function () {
      if (this.canList) {
        await this.getConveniosByParams({ ativo: false })
      }
    },
    handleAction: function (item, action) {
      this.confirmDialogItem = item
      this.confirmDialogAction = action

      this.setDialogMessage({
        title:
          action === 'remover'
            ? 'Remoção'
            : action === 'ativar'
            ? 'Ativação'
            : 'Desativação',
        message: `Deseja realmente ${action} o convênio da transportadora ${item.transportadora.nome}?`,
      })
    },
    confirmDialog: async function () {
      try {
        switch (this.confirmDialogAction) {
          case 'remover':
            await this.removerConvenio(this.confirmDialogItem)
            this.successMessage('Convênio removido com sucesso!')
            break
          case 'ativar':
            await this.ativarConvenio(this.confirmDialogItem)
            this.successMessage('Convênio ativado com sucesso!')
            break
          default:
            await this.desativarConvenio(this.confirmDialogItem)
            this.successMessage('Convênio desativado com sucesso!')
        }
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
  },
}
</script>
